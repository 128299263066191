/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trustpilot-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i3 from "@angular/common";
import * as i4 from "./trustpilot-box.component";
var styles_TrustpilotBoxComponent = [i0.styles];
var RenderType_TrustpilotBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrustpilotBoxComponent, data: {} });
export { RenderType_TrustpilotBoxComponent as RenderType_TrustpilotBoxComponent };
function View_TrustpilotBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "trustpilot-widget"], ["data-businessunit-id", "4fd81bad00006400051673a7"], ["data-locale", "en-US"], ["data-style-height", "28px"], ["data-style-width", "100%"], ["data-template-id", "5406e65db0d04a09e042d5fc"], ["data-theme", "dark"], ["id", "trust-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://www.trustpilot.com/review/businessclassguru.com"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trustpilot"]))], null, null); }
export function View_TrustpilotBoxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["id", "trustpilot-box"]], null, [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        _co.showBox = true;
        var pd_0 = (_co.activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { preRender: [0, "preRender"] }, { deferLoad: "deferLoad" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrustpilotBoxComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showBox; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TrustpilotBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trustpilot-box", [], null, null, null, View_TrustpilotBoxComponent_0, RenderType_TrustpilotBoxComponent)), i1.ɵdid(1, 114688, null, 0, i4.TrustpilotBoxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrustpilotBoxComponentNgFactory = i1.ɵccf("app-trustpilot-box", i4.TrustpilotBoxComponent, View_TrustpilotBoxComponent_Host_0, {}, {}, []);
export { TrustpilotBoxComponentNgFactory as TrustpilotBoxComponentNgFactory };
