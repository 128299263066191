/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trustpilot-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i3 from "@angular/common";
import * as i4 from "./trustpilot-grid.component";
var styles_TrustpilotGridComponent = [i0.styles];
var RenderType_TrustpilotGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrustpilotGridComponent, data: {} });
export { RenderType_TrustpilotGridComponent as RenderType_TrustpilotGridComponent };
function View_TrustpilotGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 reviews-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verified reviews from our customers"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For transparency and credibility we use Trustpilot to collect verified reviews from customers. "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "trustpilot-widget"], ["data-businessunit-id", "4fd81bad00006400051673a7"], ["data-locale", "en-US"], ["data-review-languages", "en"], ["data-stars", "1,2,3,4,5"], ["data-style-height", "240px"], ["data-style-width", "100%"], ["data-template-id", "54ad5defc6454f065c28af8b"], ["data-theme", "light"], ["id", "trust-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["href", "https://www.trustpilot.com/review/wholesale-flights.com"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trustpilot"]))], null, null); }
export function View_TrustpilotGridComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["id", "trustpilot-grid"]], null, [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        _co.showTrustGrid = true;
        var pd_0 = (_co.activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], { preRender: [0, "preRender"] }, { deferLoad: "deferLoad" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrustpilotGridComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showTrustGrid; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TrustpilotGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trustpilot-grid", [], null, null, null, View_TrustpilotGridComponent_0, RenderType_TrustpilotGridComponent)), i1.ɵdid(1, 114688, null, 0, i4.TrustpilotGridComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrustpilotGridComponentNgFactory = i1.ɵccf("app-trustpilot-grid", i4.TrustpilotGridComponent, View_TrustpilotGridComponent_Host_0, {}, {}, []);
export { TrustpilotGridComponentNgFactory as TrustpilotGridComponentNgFactory };
