import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-trustpilot-box',
  templateUrl: './trustpilot-box.component.html',
  styleUrls: ['./trustpilot-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrustpilotBoxComponent implements OnInit {
  showBox = false;
  constructor() { }

  ngOnInit() {

  }

  activate(): void {
    setTimeout(() => {
      const trustbox = document.getElementById('trust-box');
      window.Trustpilot.loadFromElement(trustbox);
      }, 0);
  }
}
