<!-- Header with Background Image -->
<header [lazyLoad]="cdnPath + '/assets/img/bg.jpg'" >
    <div  class="container px-2 px-md-0">
        <div class="row top-row">

            <div class="col-lg-12 top-text">
                <h1>Luxury Travel For Wholesale&nbsp;Prices</h1>
                <span>Save 30-70% OFF Business Class Flights, Cruises &&nbsp;Hotels</span>
            </div>

            <!-- Nav tabs -->  
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active">Flights</a>
                </li>
                <li class="nav-item" >
                    <a class="nav-link" routerlink="/hotels" (click)="routeTo('/hotels')" >Hotels</a>
                </li>
                <li class="nav-item" >
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('/cruises')">Cruises<label class="text-danger pr-1">NEW!</label></a>
                </li>
            </ul>
            
            <div class="col-md-12 bg-form">
                <div  [preRender]="false"  (deferLoad)=" initFields();" class="margin-bottom">
                    <form [formGroup]="roundTripForm">
                        <div class="row">
                            <div class="trip-type col-md-11" >
                                <a class="tripClick" (click)="openTripTypeMobile()" style="color: #212529;">
                                    <span class="tripClick"  *ngIf = "queryFlightParams.tripType == 'Round'">Round-Trip </span>
                                    <span class="tripClick" *ngIf = "queryFlightParams.tripType == 'OneWay'">One-Way </span>
                                    <span class="tripClick"  *ngIf = "queryFlightParams.tripType == 'MultiCity'">Multi-city </span>
                                    <i class="tripClick fa fa-angle-down" aria-hidden="true"></i>
                                </a>
                                <a class="passengersClick" (click)="openTravelersMobile()" style="color: #212529;">
                                    {{queryFlightParams.passengers}} {{queryFlightParams.passengers > 1 ? 'Travelers' : 'Traveler'}}
                                    <i class="passengersClick fa fa-angle-down" aria-hidden="true"></i>
                                </a>
                                <a class="cabinClick" (click)="openCabinTypeMobile()" style="color: #212529;">
                                    {{queryFlightParams.cabin == 'PREMIUMECO' ? 'Premium Economy' : capitalize(queryFlightParams.cabin)}}
                                    <span class="cabinClick" *ngIf="queryFlightParams.cabin != 'PREMIUMECO'">Class </span>
                                    <i class="cabinClick fa fa-angle-down" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                        <div *ngIf="queryFlightParams.tripType=='Round'" class="row" id="round-trip-form">
                            <div class="col-6 col-md-4 padding-none flying-from form-label-group">
                                <input id="from-rt" [ngClass]="{'has-error': !searchFormValid && !roundTripForm.get('flyingFrom').valid}"
                                       [attr.disabled]="formSending?'':null" type="text" name="from-rt" class="form-control" placeholder="Flying from?"
                                       aria-required="true" aria-invalid="false" appClearIcon autocomplete="off" [matAutocomplete]="autoFrom"
                                       formControlName="flyingFrom" [(ngModel)]="flight.from"
                                       (click)="$event.target.select(); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);" (focus)="changeMustSubmit(false)"
                                       (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoFrom="matAutocomplete" (optionSelected)="jumpToNextControl(flyingTo)">
                                    <mat-option *ngFor="let city of filteredFromData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="from-rt" [class.not-empty-control]="autoFrom._isOpen || !!roundTripForm.get('flyingFrom').value" class="icon-departure">Flying from?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                            </div>
                            <div class="col-6 col-md-4 with-padding flying-to form-label-group">
                                <input id="flyingToRound" [ngClass]="{'has-error': !searchFormValid && !roundTripForm.get('flyingTo').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingTo type="text" name="to-rt" class="form-control"
                                       placeholder="Flying to?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       [matAutocomplete]="autoTo" formControlName="flyingTo" [(ngModel)]="flight.to"
                                       (click)="$event.target.select(); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, false);"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoTo="matAutocomplete" (optionSelected)="jumpToNextControl(dpDepart, 'flyingToRound')">
                                    <mat-option *ngFor="let city of filteredToData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingToRound" [class.not-empty-control]="autoTo._isOpen || !!roundTripForm.get('flyingTo').value" class="icon-destination">Flying to?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                            </div>
                            <div class="col-12 col-md-4 padding-none">
                                <div class="row date-input">
                                    <div class="col-6 col-md-6 padding-none depart-rt form-label-group" (click)="activeDepart=true" (appClickOutside)="onBlur('roundTripDepart')">
                                        <input id="roundTripDepart" type="text" name="depart-rt" class="form-control" placeholder="Depart" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeDepart, 'has-error': !searchFormValid && !roundTripForm.get('departDate').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpDepart="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'roundTripDepart')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpDepart.toggle(); changeMustSubmit(true); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateDepart"
                                               required formControlName="departDate" [readonly]="true" [(ngModel)]="flight.departDate"
                                               (ngModelChange)="initReturnDatepicker(); jumpToNextControl(dpReturn)">
                                        <label for="roundTripDepart" [class.not-empty-control]="dpDepart.isOpen() || !!roundTripForm.get('departDate').value?.day" class="icon-depart-date">Depart</label>
                                    </div>
                                    <div class="dash" [ngClass]="{'has-error':  !searchFormValid && !roundTripForm.get('departDate').valid && !roundTripForm.get('returnDate').valid, 'disabled': formSending}">
                                    </div>
                                    <div class="col-6 col-md-6 padding-none return-rt form-label-group" (click)="activeReturn=true" (appClickOutside)="onBlur('roundTripReturn')">
                                        <input id="roundTripReturn" type="text" name="return-rt" class="form-control" placeholder="Return" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeReturn, 'has-error': !searchFormValid && !roundTripForm.get('returnDate').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpReturn="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpReturn, 'roundTripReturn')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpReturn.toggle();changeMustSubmit(true); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateReturn"
                                               [(ngModel)]="returnDate" required formControlName="returnDate" [readonly]="true">
                                        <label for="roundTripReturn" [class.not-empty-control]="dpReturn.isOpen() || !!roundTripForm.get('returnDate').value?.day" class="icon-return-date">Return</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="oneWayForm">
                        <div *ngIf="queryFlightParams.tripType=='OneWay'" class="row" id="one-way-form">
                            <div class="col-6 col-md-4 padding-none flying-from form-label-group">
                                <input id="oneWayFrom" [ngClass]="{'has-error': !searchFormValid && !oneWayForm.get('flyingFrom').valid}"
                                       [attr.disabled]="formSending?'':null" type="text" name="from-rt" class="form-control" placeholder="Flying from?"
                                       aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon [(ngModel)]="flight.from"
                                       required formControlName="flyingFrom" [matAutocomplete]="autoFrom"
                                       (click)="$event.target.select(); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, true);"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoFrom="matAutocomplete" (optionSelected)="jumpToNextControl(flyingToOneWay)">
                                    <mat-option *ngFor="let city of filteredFromOneWayData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="oneWayFrom" [class.not-empty-control]="autoFrom._isOpen || !!oneWayForm.get('flyingFrom').value" class="icon-departure">Flying from?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                            </div>
                            <div class="col-6 col-md-4 with-padding flying-to form-label-group">
                                <input id="flyingToOneWay" [ngClass]="{'has-error': !searchFormValid && !oneWayForm.get('flyingTo').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingToOneWay type="text" name="to-rt" class="form-control"
                                       placeholder="Flying to?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       [(ngModel)]="flight.to" required [matAutocomplete]="autoTo" formControlName="flyingTo"
                                       (click)="$event.target.select(); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, false);"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)" />
                                <mat-autocomplete #autoTo="matAutocomplete" (optionSelected)="jumpToNextControl(dpDepart, 'flyingToOneWay')">
                                    <mat-option *ngFor="let city of filteredToOneWayData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingToOneWay" [class.not-empty-control]="autoTo._isOpen || !!oneWayForm.get('flyingTo').value" class="icon-destination">Flying to?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                            </div>
                            <div class="col-12 col-md-4 padding-none form-label-group">
                                <div class="row date-input">
                                    <div class="col-12 col-md-12 padding-none depart-rt" (click)="activeDepart=true" (appClickOutside)="onBlur('oneWayDepart')">
                                        <input id="oneWayDepart" type="text" name="depart-rt" class="form-control" placeholder="Depart" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeDepart, 'has-error': !searchFormValid && !oneWayForm.get('departDate').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpDepart="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'oneWayDepart')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpDepart.toggle();changeMustSubmit(true); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateDepart"
                                               [(ngModel)]="flight.departDate" required formControlName="departDate" [readonly]="true">
                                        <label for="oneWayDepart" [class.not-empty-control]="dpDepart.isOpen() || !!oneWayForm.get('departDate').value?.day" class="icon-depart-date">Depart</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="multiCityForm">
                        <div *ngIf="queryFlightParams.tripType=='MultiCity'" class="row" id="multi-city-form">
                            <div class="col-6 col-md-4 padding-none flying-from form-label-group">
                                <input id="flyingFromMultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingFrom').valid}"
                                       [attr.disabled]="formSending?'':null" type="text" name="from-rt" class="form-control" placeholder="Flying from?"
                                       aria-required="true" aria-invalid="false" appClearIcon autocomplete="off" [(ngModel)]="flight.from"
                                       required formControlName="flyingFrom" [matAutocomplete]="autoFrom"
                                       (click)="$event.target.select();
                                       openMobileFromToDialog(multiCityForm.get('flyingFrom'), multiCityForm.get('flyingTo'), $event, true, 'departDate');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoFrom="matAutocomplete" (optionSelected)="jumpToNextControl(flyingToMulty)">
                                    <mat-option *ngFor="let city of filteredFromMultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingFromMultiCity" [class.not-empty-control]="autoFrom._isOpen || !!multiCityForm.get('flyingFrom').value" class="icon-departure">Flying from?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                            </div>
                            <div class="col-6 col-md-4 with-padding flying-to form-label-group">
                                <input id="flyingToMultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingTo').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingToMulty type="text" name="to-rt" class="form-control"
                                       placeholder="Flying to?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       [(ngModel)]="flight.to" required [matAutocomplete]="autoTo" formControlName="flyingTo"
                                       (click)="$event.target.select();
                                       openMobileFromToDialog(multiCityForm.get('flyingFrom'), multiCityForm.get('flyingTo'), $event, false, 'departDate');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoTo="matAutocomplete" (optionSelected)="jumpToNextControl(dpDepart, 'flyingToMultiCity')">
                                    <mat-option *ngFor="let city of filteredToMultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingToMultiCity" [class.not-empty-control]="autoTo._isOpen || !!multiCityForm.get('flyingTo').value" class="icon-destination">Flying to?</label>
                                <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                            </div>
                            <div class="col-12 col-md-4 padding-none form-label-group">
                                <div class="row date-input">
                                    <div class="col-12 col-md-12 padding-none depart-rt" (click)="activeDepart=true" (appClickOutside)="onBlur('multiCityDepart')">
                                        <input id="multiCityDepart" type="text" name="depart-rt" class="form-control" placeholder="Depart" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeDepart, 'has-error': !searchFormValid && !multiCityForm.get('departDate').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpDepart="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'multiCityDepart')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpDepart.toggle();changeMustSubmit(true);
                                               openMobileDepartReturnDateDialog(multiCityForm.get('departDate'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateDepart"
                                               formControlName="departDate" [(ngModel)]="flight.departDate" [readonly]="true" (ngModelChange)="initDepartDatapicker();jumpToNextControl(flyingFromMulty1)">
                                        <label for="multiCityDepart" [class.not-empty-control]="dpDepart.isOpen() || !!multiCityForm.get('departDate').value?.day" class="icon-depart-date">Depart</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 padding-none flying-from form-label-group">
                                <input id="flyingFrom1MultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingFrom1').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingFromMulty1 type="text" name="from-rt" class="form-control"
                                       placeholder="Flying from?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       formControlName="flyingFrom1" [(ngModel)]="flight1.from" [matAutocomplete]="autoFrom1"
                                       (click)="$event.target.select();
                                       openMobileFromToDialog(multiCityForm.get('flyingFrom1'), multiCityForm.get('flyingTo1'), $event, true, 'departDate1');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoFrom1="matAutocomplete" (optionSelected)="jumpToNextControl(flyingToMulty1)">
                                    <mat-option *ngFor="let city of filteredFrom1MultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingFrom1MultiCity" [class.not-empty-control]="autoFrom1._isOpen || !!multiCityForm.get('flyingFrom1').value" class="icon-departure">Flying from?</label>
                                <span class="xIcon" [class.xIcon-active]="flight1.from?.length"></span>
                            </div>
                            <div class="col-6 col-md-4 with-padding flying-to form-label-group">
                                <input id="flyingTo1MultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingTo1').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingToMulty1 type="text" name="to-rt" class="form-control"
                                       placeholder="Flying to?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       formControlName="flyingTo1" [(ngModel)]="flight1.to" [matAutocomplete]="autoTo1"
                                       (click)="$event.target.select();
                                       openMobileFromToDialog(multiCityForm.get('flyingFrom1'), multiCityForm.get('flyingTo1'), $event, false, 'departDate1');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoTo1="matAutocomplete" (optionSelected)="jumpToNextControl(dpDepart1, 'flyingTo1MultiCity')">
                                    <mat-option *ngFor="let city of filteredTo1MultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingTo1MultiCity" [class.not-empty-control]="autoTo1._isOpen || !!multiCityForm.get('flyingTo1').value" class="icon-destination">Flying to?</label>
                                <span class="xIcon" [class.xIcon-active]="flight1.to?.length"></span>
                            </div>
                            <div class="col-12 col-md-4 padding-none form-label-group">
                                <div class="row date-input">
                                    <div class="col-12 col-md-12 padding-none depart-rt" (click)="activeDepart1=true" (appClickOutside)="onBlur('multiCityDepart1')">
                                        <input id="multiCityDepart1" type="text" name="depart-rt" class="form-control" placeholder="Depart" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeDepart1, 'has-error': !searchFormValid && !multiCityForm.get('departDate1').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpDepart1="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpDepart1, 'multiCityDepart1')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpDepart1.toggle();changeMustSubmit(true); openMobileDepartReturnDateDialog(multiCityForm.get('departDate1'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateDepart1"
                                               formControlName="departDate1" [(ngModel)]="flight1.departDate" [readonly]="true" (ngModelChange)="initDepartDatapicker();jumpToNextControl(flyingFromMulty2)">
                                        <label for="multiCityDepart1" [class.not-empty-control]="dpDepart1.isOpen() || !!multiCityForm.get('departDate1').value?.day" class="icon-depart-date">Depart</label>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="!showThirdFlight" class="col-6 col-md-4 padding-none flying-from form-label-group">
                                <input id="flyingFrom2MultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingFrom2').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingFromMulty2 type="text" name="from-rt" class="form-control"
                                       placeholder="Flying from?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       formControlName="flyingFrom2" [(ngModel)]="flight2.from" [matAutocomplete]="autoFrom2"
                                       (click)="$event.target.select(); openMobileFromToDialog(multiCityForm.get('flyingFrom2'), multiCityForm.get('flyingTo2'), $event, true, 'departDate2');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoFrom2="matAutocomplete" (optionSelected)="jumpToNextControl(flyingToMulty2)">
                                    <mat-option *ngFor="let city of filteredFrom2MultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingFrom2MultiCity" [class.not-empty-control]="autoFrom2._isOpen || !!multiCityForm.get('flyingFrom2').value" class="icon-departure">Flying from?</label>
                                <span class="xIcon" [class.xIcon-active]="flight2.from?.length"></span>
                            </div>
                            <div [hidden]="!showThirdFlight" class="col-6 col-md-4 with-padding flying-to form-label-group">
                                <input id="flyingTo2MultiCity" [ngClass]="{'has-error': !searchFormValid && !multiCityForm.get('flyingTo2').valid}"
                                       [attr.disabled]="formSending?'':null" #flyingToMulty2 type="text" name="to-rt" class="form-control"
                                       placeholder="Flying to?" aria-required="true" aria-invalid="false" autocomplete="off" appClearIcon
                                       formControlName="flyingTo2" [(ngModel)]="flight2.to" [matAutocomplete]="autoTo2"
                                       (click)="$event.target.select(); openMobileFromToDialog(multiCityForm.get('flyingFrom2'), multiCityForm.get('flyingTo2'), $event, false, 'departDate2');"
                                       (focus)="changeMustSubmit(false)" (focusout)="onFocusOutAirportInput($event)">
                                <mat-autocomplete #autoTo2="matAutocomplete" (optionSelected)="jumpToNextControl(dpDepart2, 'flyingTo2MultiCity')">
                                    <mat-option *ngFor="let city of filteredTo2MultiCityData | async" [value]="city">
                                        <span>{{ city }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <label for="flyingTo2MultiCity" [class.not-empty-control]="autoTo2._isOpen || !!multiCityForm.get('flyingTo2').value" class="icon-destination">Flying to?</label>
                                <span class="xIcon" [class.xIcon-active]="flight2.to?.length"></span>
                            </div>
                            <div [hidden]="!showThirdFlight" class="col-12 col-md-4 padding-none form-label-group">
                                <div class="row date-input">
                                    <div class="col-12 col-md-12 padding-none depart-rt" (click)="activeDepart2=true" (appClickOutside)="activeDepart2=false;onBlur('multiCityDepart2')">
                                        <input id="multiCityDepart2" type="text" name="depart-rt" class="form-control" placeholder="Depart" style="cursor: pointer;"
                                               aria-required="true" aria-invalid="false" autocomplete="off" [ngClass]="{'active-datepicker' : activeDepart2, 'has-error': !searchFormValid && !multiCityForm.get('departDate2').valid}"
                                               [attr.disabled]="formSending?'':null" ngbDatepicker #dpDepart2="ngbDatepicker" (appClickOutside)="onClickedOutsideDp($event, dpDepart2, 'multiCityDepart2')"
                                               [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" [displayMonths]="displayMonths"
                                               (click)="dpDepart2.toggle();changeMustSubmit(true); openMobileDepartReturnDateDialog(multiCityForm.get('departDate2'), $event, true);"
                                               [maxDate]="maxDate" [minDate]="minDateDepart2"
                                               formControlName="departDate2" [(ngModel)]="flight2.departDate" [readonly]="true">
                                        <label for="multiCityDepart2" [class.not-empty-control]="dpDepart2.isOpen() || !!multiCityForm.get('departDate2').value?.day" class="icon-depart-date">Depart</label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showAddFlightButton" class="col-6 col-md-4 padding-none">
                                <button type="submit" class="btn-add" (click)="addFlight()"><span>+</span> Add Flight
                                </button>
                            </div>
                        </div>
                    </form>
<!--                    todo when we fill second flight, the third flight opens automatically -->
                    <form [formGroup]="queryPanelForm">
                        <div *ngIf="normalFlight" class="row justify-content-between" id="formLine">
                            <div class="col-md-4 padding-none form-label-group">
                                <input id="reqName" [ngClass]="{'has-error': !queryPanelForm.get('firstName').valid && !queryPanelValid}"
                                       [attr.disabled]="formSending?'':null" type="text" name="reqName" class="form-control" placeholder="Name"
                                       maxlength="150" aria-required="true" aria-invalid="false" autocomplete="off" formControlName="firstName"
                                       [(ngModel)]="contactData.firstName" (click)="$event.target.select(); checkContactOpeningSource($event);" appClearIcon>
                                <label for="reqName" class="icon-name" [class.not-empty-control]="!!queryPanelForm.get('firstName').value">Name</label>
                                <span class="xIcon" [class.xIcon-active]="contactData.firstName?.length"></span>
                            </div>
                            <div class="col-md-4 with-padding form-label-group">
                                <input id="reqEmail" [ngClass]="{'has-error': !queryPanelForm.get('email').valid && !queryPanelValid}"
                                       [attr.disabled]="formSending?'':null" type="text" name="reqEmail" class="form-control" placeholder="Email"
                                       maxlength="150" aria-required="true" aria-invalid="false" autocomplete="off" formControlName="email"
                                       [(ngModel)]="contactData.email" (click)="$event.target.select(); checkContactOpeningSource($event);" appClearIcon>
                                <label for="reqEmail" class="icon-email" [class.not-empty-control]="!!queryPanelForm.get('email').value">Email</label>
                                <span class="xIcon" [class.xIcon-active]="contactData.email?.length"></span>
                            </div>
                            <div class="col-md-4 padding-none form-label-group phone-label-group">
                                <input id="contactField" [ngClass]="{'has-error': !queryPanelForm.get('phone').valid && !queryPanelValid}"
                                       [attr.disabled]="formSending?'':null" type="tel" class="form-control" placeholder="Phone"
                                       formControlName="phone" [(ngModel)]="contactData.phone" #phoneSelect="ngx-phone-select"
                                       ngxPhoneSelect (click)="$event.target.select(); checkContactOpeningSource($event);"
                                       (countryChangeEvent)="countryChanged()" [textMask]="{mask: phoneMask}" appClearIcon>
                                <label for="contactField" [class.not-empty-control]="!!queryPanelForm.get('phone').value"
                                       class="with-phone">Phоne</label>
                                <span class="xIcon" [class.xIcon-active]="contactData.phone?.length"></span>
                            </div>

                            <div class="col-md-3 col-lg-4 padding-none">
                                <button type="button" class="btn" (click)="getQuote()" [attr.disabled]="formSending?'':null">Get a FREE Quote</button>
                            </div>

                            <div class="col-12 col-md-7 col-lg-6 col-xl-5 padding-none justify-content-start">
                                <div class="bundle">
                                    <span class="d-flex"><img src="assets/img/icons/checkmark_wavy.svg" alt="check">Bundle + Save</span>
                                    <div class="d-inline-block">
                                        <label class="form-check form-check-inline">
                                            Add a Hotel
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="hotelCheckbox"
                                                   [(ngModel)]="bundleSave.addHotel"
                                                   [ngModelOptions]="{ standalone: true }">
                                            <i class="checkmark"></i>
                                        </label>
                
                                        <label class="form-check form-check-inline">
                                            Add a Cruise
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   id="cruiseCheckbox"
                                                   [(ngModel)]="bundleSave.addCruise"
                                                   [ngModelOptions]="{ standalone: true }">
                                            <i class="checkmark"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="!normalFlight" class="row">
                            <div id="errorBox" class="col-xs-12 pn errorBox container">
                                <div class="alert" role="alert">We do not sell economy flights, domestic flights, or flights that cost
                                    less than $2500.
                                </div>
                            </div>
                        </div>
                    </form>
      
                    <div *ngIf="tripContent" id="trip-content" (appClickOutside)="onClickedOutsideTripContent($event)" (appTouchOutside)="onClickedOutsideTripContent($event)">
                        <div class="row cabin-select">
                            <span class="cabin-name">Travelers:</span>
                            <select class="form-control" [(ngModel)]="queryFlightParams.passengers" (change)="changeTravelers()">
                                <option *ngFor="let count of [1,2,3,4,5,6,7,8,9,10]" [ngValue]="count">{{count}}</option>
                            </select>
                        </div>
                        <div class="row cabin-close">
                            <button type="button" class="btn" (click)="tripContent=false">Close</button>
                        </div>
                    </div>
                    <div *ngIf="passengersContent" id="passengers" (appClickOutside)="onClickedOutsidePassengers($event)" (appTouchOutside)="onClickedOutsidePassengers($event)">
                        <div class="cabin-tabs row">
                            <!-- <i class="arrow">▲</i> -->
                            <span class="cabin-name">Travelers:</span>
                            <ul class="d-none d-sm-block">
                                <li *ngFor="let count of [1,2,3,4,5,6,7,8,9,10]">
                                    <a name="a1"
                                       [ngClass]="{'active': queryFlightParams.passengers == count}"
                                       (click)="queryFlightParams.passengers = count; changeTravelers(); passengersContent=false;">
                                        <span>{{count}}</span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="d-block d-sm-none" style="text-align: center; margin-left: 15px; margin-right: 15px;">
                                <li *ngFor="let count of [1,2,3,4,5,6,7,8,9,10]">
                                    <a name="a1"
                                       [ngStyle]="{'padding': (count === 10? '13px 11px' : '13px 15px')}"
                                       [ngClass]="{'active': queryFlightParams.passengers == count}"
                                       (click)="queryFlightParams.passengers = count; changeTravelers(); passengersContent=false;">
                                        <span>{{count}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="cabinContent" id="cabin-type" (appClickOutside)="onClickedOutsideCabinType($event)" (appTouchOutside)="onClickedOutsideCabinType($event)">
                        <div class="cabin-tabs row">
                            <!-- <i class="arrow">▲</i> -->
                            <!--              <span class="cabin-name">Select cabin:</span>-->
                            <ul>
                                <li id="BUSINESS">
                                    <a name="a1" [ngClass]="{'active': queryFlightParams.cabin == 'BUSINESS'}" (click)="changeCabinType('BUSINESS');cabinContent=false;">
                                        <span>Business</span>
                                    </a>
                                </li>
                                <li id="FIRST">
                                    <a name="a2" [ngClass]="{'active': queryFlightParams.cabin == 'FIRST'}" (click)="changeCabinType('FIRST');cabinContent=false;">
                                        <span>First</span>
                                    </a>
                                </li>
                                <li id="PREMIUMECO">
                                    <a name="a3" [ngClass]="{'active': queryFlightParams.cabin == 'PREMIUMECO'}" (click)="changeCabinType('PREMIUMECO');cabinContent=false;">
                                        <span>Premium Economy</span>
                                    </a>
                                </li>
                                <li id="ECONOMY">
                                    <a name="a4" [ngClass]="{'active': queryFlightParams.cabin == 'ECONOMY'}" (click)="changeCabinType('ECONOMY');cabinContent=false;">
                                        <span>Economy</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div *ngIf="tripTypeContent" id="trip-type" (appClickOutside)="onClickedOutsideTripType($event)" (appTouchOutside)="onClickedOutsideTripType($event)">
                        <div class="cabin-tabs row">
                            <!-- <i class="arrow">▲</i> -->
                            <!--              <span class="cabin-name">Select trip:</span>-->
                            <ul>
                                <li>
                                    <a id="round-trip" valtype="round" [ngClass]="{'active': queryFlightParams.tripType == 'Round'}" (click)="changeTripType('Round', roundTripForm);tripTypeContent=false;">
                                        <span>Round-Trip</span>

                                    </a>
                                </li>
                                <li>
                                    <a id="one-way" valtype="oneway" [ngClass]="{'active': queryFlightParams.tripType == 'OneWay'}"  (click)="changeTripType('OneWay', oneWayForm);tripTypeContent=false;">
                                        <span>One-way</span>
                                    </a>
                                </li>
                                <li>
                                    <a id="multi-city"  valtype="multi" [ngClass]="{'active': queryFlightParams.tripType == 'MultiCity'}" (click)="changeTripType('MultiCity', multiCityForm);tripTypeContent=false">
                                        <span>Multi-city</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="searchbox-down-sections-indents privacy-container row pt-3 air-logos d-none d-md-flex">
            <div class="privacy-section">
                <strong>*No Spam - only phone-exclusive deals. No purchase necessary.</strong>
                By providing your contact details and clicking on "Get a Free Quote" you agree to be contacted for travel information via automated
                phone and text messages and by email. Your consent to receive such messages is not a condition of purchase.
                We respect your <strong class="link" routerLink="/privacy-policy">privacy</strong>.
            </div>
        </div>

        <div class="searchbox-down-sections-indents row py-3 air-logos d-none d-md-flex">
            <div class="col">
              <img alt="Airfrance" src="assets/img/logos/flights/01.svg">
            </div>
            <div class="col">
              <img alt="Emirates" src="assets/img/logos/flights/02.svg">
            </div>
            <div class="col d-none d-md-block">
              <img alt="Cathay Pacific" src="assets/img/logos/flights/03.svg">
            </div>
    
            <div class="col  d-none d-md-block">
              <img alt="British Airways" src="assets/img/logos/flights/04.svg">
            </div>
            <div class="col">
              <img alt="Lufthansa" src="assets/img/logos/flights/05.svg">
            </div>
        </div>

    </div>
</header>
