<!-- Page Content Customers Block-->
<section id="trustpilot-grid"  [preRender]="false"
         (deferLoad)="showTrustGrid=true; activate();" id="trustpilot-grid">
  <div *ngIf="showTrustGrid" class="container">
    <div class="row">
      <div class="col-sm-12 reviews-text">
        <h2 class="mt-4">Verified reviews from our customers</h2>
        <p>
          For transparency and credibility we use Trustpilot to collect verified reviews from customers.
        </p>
      </div>

      <div class="col-sm-12">
        <!-- TrustBox widget - Slider -->
        <div id="trust-grid" class="trustpilot-widget" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="4fd81bad00006400051673a7" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="en">
          <a href="https://www.trustpilot.com/review/wholesale-flights.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
      </div>
      
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</section>
